import axios from 'axios';
import { logout } from '../store/slices/adminSlice';

let store;

export const injectStore = (_store) => {
  store = _store;
};

export const defaultHttp = axios.create();

const http = axios.create();

http.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const apiToken = state.admin?.token;

    if (apiToken) {
      config.headers.Authorization = `Bearer ${apiToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // add logic of refresh token
    if (error?.response?.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export default http;
