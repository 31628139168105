import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { defaultHttp } from 'utils/http';
import { apiRoutes } from 'routes/api';
import { handleErrorResponse } from 'utils/index';

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);

    defaultHttp
      .post(apiRoutes.forgot_password, {
        email: values.email,
      })
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        handleErrorResponse(error);
        setLoading(false);
      });
  };

  return (
    <>
      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-left text-opacity-30 tracking-wide">
        Forgot Password
      </h1>
      {emailSent ? (
        <div className="text-center text-green-600">
          A link has been sent to your email. Please check your email.
        </div>
      ) : (
        <Form
          className="space-y-4 md:space-y-6"
          form={form}
          name="forgot-password"
          onFinish={onSubmit}
          layout="vertical"
          requiredMark={false}
          initialValues={{}}
        >
          <div>
            <Form.Item
              name="email"
              label={
                <p className="block text-sm font-medium text-gray-900">Email</p>
              }
              rules={[
                {
                  required: true,
                  message: 'Please enter your email',
                },
                {
                  type: 'email',
                  message: 'Invalid email address',
                },
              ]}
            >
              <Input
                placeholder="name@example.com"
                className="bg-gray-50 text-gray-900 sm:text-sm py-1.5"
              />
            </Form.Item>
          </div>
          <div className="text-center">
            <Button
              className="mt-4 bg-primary"
              block
              loading={loading}
              type="primary"
              size="large"
              htmlType="submit"
            >
              Send Reset Link
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default ForgotPassword;
