import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Skeleton } from 'antd';
import './progressBar.css';

const ProgressBar = ({ spinner = false }) => {
  NProgress.configure({ showSpinner: spinner });

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  });

  return (
    <>
      <Skeleton />
    </>
  );
};

export default ProgressBar;
