export const webRoutes = {
  home: '/',
  login: '/login',
  forgot_password: '/forgot-password',
  reset_password: '/reset-password',
  change_password: '/change-password',
  logout: '/logout',
  dashboard: '/dashboard',
  users: '/users',
  about: '/about',
  locations: '/locations',
  locations_add: '/locations/add',
  reviews_form: '/reviews-form',
  thank_you: '/thank-you',
  customers: '/customers',
  reviews: '/reviews',
};
