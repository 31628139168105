import enUSIntl from 'antd/locale/en_US';

export const antdConfig = {
  theme: {
    token: {
      colorPrimary: '#6F2EDE',
    },
  },
  locale: enUSIntl,
};
