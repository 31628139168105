import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { webRoutes } from './web';

const RequireAuth = ({ children }) => {
  const admin = useSelector((state) => state.admin);
  const location = useLocation();

  if (!admin) {
    return <Navigate to={webRoutes.login} state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
