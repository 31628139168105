import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { browserRouter } from 'routes/browserRouter';

const App = () => (
  <div>
    <RouterProvider router={browserRouter} />
  </div>
);

export default App;
