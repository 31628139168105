import { createBrowserRouter } from 'react-router-dom';
import AuthLayout from '../components/auth/AuthLayout';
import ErrorPage from '../components/errorPage';
import Layout from '../components/layout';
import Redirect from '../components/layout/Redirect';
// import NotFoundPage from '../components/notfoundPage';
import { webRoutes } from './web';
import loadable from '@loadable/component';
import ProgressBar from '../components/loader/progressBar';
import RequireAuth from './requireAuth';
import Login from '../components/auth/Login';
import ForgotPassword from 'components/auth/ForgotPassword';
import ResetPassword from 'components/auth/ResetPassword';

const errorElement = <ErrorPage />;
const fallbackElement = <ProgressBar />;

const Dashboard = loadable(() => import('../components/dashboard'), {
  fallback: fallbackElement,
});
const Locations = loadable(() => import('../components/locations'), {
  fallback: fallbackElement,
});
const LocationsAddEdit = loadable(
  () => import('../components/locations/addEdit'),
  {
    fallback: fallbackElement,
  }
);
const Customers = loadable(() => import('../components/customers'), {
  fallback: fallbackElement,
});
const Reviews = loadable(() => import('../components/reviews'), {
  fallback: fallbackElement,
});

const ReviewsForm = loadable(() => import('../components/reviews-form'), {
  fallback: fallbackElement,
});

const ThankYou = loadable(() => import('../components/thank-you'), {
  fallback: fallbackElement,
});

const ChangePassword = loadable(
  () => import('../components/auth/ChangePassword'),
  {
    fallback: fallbackElement,
  }
);

export const browserRouter = createBrowserRouter([
  {
    path: webRoutes.home,
    element: <Redirect />,
    errorElement: errorElement,
  },
  {
    path: webRoutes.reviews_form,
    element: <ReviewsForm />,
  },
  {
    path: webRoutes.thank_you,
    element: <ThankYou />,
  },

  // auth routes
  {
    element: <AuthLayout />,
    errorElement: errorElement,
    children: [
      {
        path: webRoutes.login,
        element: <Login />,
      },
      {
        path: webRoutes.forgot_password,
        element: <ForgotPassword />,
      },
      {
        path: webRoutes.reset_password,
        element: <ResetPassword />,
      },
    ],
  },

  // protected routes
  {
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    errorElement: errorElement,
    children: [
      {
        path: webRoutes.dashboard,
        element: <Dashboard />,
      },
      {
        path: webRoutes.locations,
        element: <Locations />,
      },
      {
        path: `${webRoutes.locations}/add`,
        element: <LocationsAddEdit />,
      },
      {
        path: `${webRoutes.locations}/edit/:id`,
        element: <LocationsAddEdit />,
      },
      {
        path: webRoutes.customers,
        element: <Customers />,
      },
      {
        path: webRoutes.reviews,
        element: <Reviews />,
      },
      {
        path: webRoutes.change_password,
        element: <ChangePassword />,
      },
    ],
  },

  // 404
  // {
  //   path: '*',
  //   element: <NotFoundPage />,
  //   errorElement: errorElement,
  // },
]);
