import { API_URL } from '../utils';

export const apiRoutes = {
  login: `${API_URL}/login`,
  forgot_password: `${API_URL}/forgot-password`,
  reset_password: `${API_URL}/reset-password`,
  change_password: `${API_URL}/change-password`,
  logout: `${API_URL}/logout`,
  users: `${API_URL}/users`,
  reviews: `${API_URL}/review`,
  locations: `${API_URL}/location`,
  customers: `${API_URL}/store-review`,
  dashboard: `${API_URL}/dashboard`,
  noreview: `${API_URL}/store-review/no-review`,
};
