import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleErrorResponse } from 'utils/index';
import { defaultHttp } from 'utils/http';
import { apiRoutes } from 'routes/api';

const ResetPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token) {
      setToken(token);
    } else {
      handleErrorResponse('Invalid or missing token');
      navigate('/login');
    }
  }, [location, navigate]);

  const handleResetPassword = async (values) => {
    if (!token) {
      handleErrorResponse('Token is missing');
      return;
    }
    setLoading(true);
    try {
      defaultHttp
        .post(apiRoutes.reset_password, {
          newPassword: values.newPassword,
          token,
        })
        .then(() => {
          message.success('Password reset successfully');
          setLoading(false);
          navigate('/login');
        })
        .catch((error) => {
          handleErrorResponse(error);
          setLoading(false);
        });
    } catch (error) {
      message.error('Failed to reset password. Please try again.');
    }
  };

  return (
    <>
      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-left text-opacity-30 tracking-wide">
        Reset Password
      </h1>
      <Form
        className="space-y-4 md:space-y-6"
        form={form}
        name="reset-password"
        onFinish={handleResetPassword}
        layout="vertical"
        requiredMark={false}
        initialValues={{}}
      >
        <div>
          <Form.Item
            name="newPassword"
            label={
              <p className="block text-sm font-medium text-gray-900">
                New Password
              </p>
            }
            rules={[
              {
                required: true,
                message: 'Please enter your new password',
              },
            ]}
          >
            <Input.Password
              placeholder="••••••••"
              visibilityToggle={false}
              className="bg-gray-50 text-gray-900 sm:text-sm py-1.5"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="confirmPassword"
            label={
              <p className="block text-sm font-medium text-gray-900">
                Confirm Password
              </p>
            }
            rules={[
              {
                required: true,
                message: 'Please confirm your new password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="••••••••"
              visibilityToggle={false}
              className="bg-gray-50 text-gray-900 sm:text-sm py-1.5"
            />
          </Form.Item>
        </div>
        <div className="text-center">
          <Button
            className="mt-4 bg-primary"
            block
            loading={loading}
            type="primary"
            size="large"
            htmlType="submit"
          >
            Reset Password
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ResetPassword;
