import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { injectStore } from './utils/http';
import { persistStore } from 'redux-persist';
import { antdConfig } from 'constants/index';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from 'components/loader/index';

const root = ReactDOM.createRoot(document.getElementById('root'));

const persistor = persistStore(store);
injectStore(store);

root.render(
  <ConfigProvider {...antdConfig}>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
