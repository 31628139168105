import { webRoutes } from '../../routes/web';
import { BiHomeAlt2 } from 'react-icons/bi';
import Icon, {
  ShopOutlined,
  UsergroupAddOutlined,
  HeartOutlined,
} from '@ant-design/icons';

export const sidebar = [
  {
    path: webRoutes.dashboard,
    key: webRoutes.dashboard,
    name: 'Dashboard',
    icon: <Icon component={BiHomeAlt2} />,
  },
  {
    path: webRoutes.locations,
    key: webRoutes.locations,
    name: 'Locations',
    icon: <ShopOutlined />,
  },
  {
    path: webRoutes.customers,
    key: webRoutes.customers,
    name: 'Review Invites',
    icon: <UsergroupAddOutlined />,
  },
  {
    path: webRoutes.reviews,
    key: webRoutes.reviews,
    name: 'Reviews',
    icon: <HeartOutlined />,
  },
];
