import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { webRoutes } from '../../routes/web';
import { Dropdown } from 'antd';
import { ProLayout, DefaultFooter } from '@ant-design/pro-components';
import Icon, { LogoutOutlined, KeyOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/slices/adminSlice';
import { memo } from 'react';
import { sidebar } from './sidebar';
import { RiShieldUserFill } from 'react-icons/ri';

const Layout = () => {
  const admin = useSelector((state) => state.admin);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultProps = {
    title: 'RMS InfoSolution',
    logo: 'https://www.rmsinfosolution.com/assets/img/logos/footer_logo.jpeg',
    fixedHeader: true,
    fixSiderbar: true,
    // layout: CONFIG.theme.sidebarLayout,
    layout: 'top',
    route: {
      routes: sidebar,
    },
  };

  const logoutAdmin = () => {
    dispatch(logout());
    navigate(webRoutes.login, {
      replace: true,
    });
  };

  return (
    <div className="h-screen">
      <ProLayout
        {...defaultProps}
        token={{
          sider: {
            colorMenuBackground: 'white',
          },
          // header: {
          //   colorBgHeader: '#181824',
          //   colorHeaderTitle: '#fff',
          //   colorTextMenu: '#dfdfdf',
          //   colorTextMenuSecondary: '#dfdfdf',
          //   colorTextMenuSelected: '#fff',
          //   colorBgMenuItemSelected: '#22272b',
          //   colorTextMenuActive: 'rgba(255,255,255,0.85)',
          //   colorTextRightActionsItem: '#dfdfdf',
          // },
          // header: {
          //   colorBgHeader: '#6F2EDE',
          header: {
            // colorBgHeader: '#6F2EDE',
            // colorHeaderTitle: '#fff',
            // colorTextMenu: '#fff',
            // colorTextMenuSecondary: '#fff',
            colorTextMenuSelected: '#fff',
            colorBgMenuItemSelected: '#6F2EDE',
            // colorBgMenuItemSelected: '#5A24B1',
            // colorBgMenuItemSelected: '#DE6E2E',
            // colorBgMenuItemSelected: '#F74D4D',
            // colorTextMenuActive: 'rgba(255,255,255,0.85)',
            // colorTextRightActionsItem: '#fff',
          },
          // },
        }}
        location={location}
        onMenuHeaderClick={() => navigate(webRoutes.dashboard)}
        menuItemRender={(item, dom) => (
          <a
            onClick={(e) => {
              e.preventDefault();
              item.path && navigate(item.path);
            }}
            href={item.path}
          >
            {dom}
          </a>
        )}
        avatarProps={{
          icon: admin?.user?.Logo ? (
            <img src={`${admin?.user?.Logo}`} alt="Logo" />
          ) : (
            <Icon component={RiShieldUserFill} />
          ),
          className: 'bg-primary bg-opacity-20 text-primary text-opacity-90',
          size: 'small',
          shape: 'square',
          title: admin?.user?.Name || 'Admin',
          render: (_, dom) => {
            return (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'change-password',
                      icon: <KeyOutlined />,
                      label: 'Change Password',
                      onClick: () => {
                        navigate(webRoutes.change_password);
                      },
                    },
                    {
                      key: 'logout',
                      icon: <LogoutOutlined />,
                      label: 'Logout',
                      onClick: () => {
                        logoutAdmin();
                      },
                    },
                  ],
                }}
              >
                {dom}
              </Dropdown>
            );
          },
        }}
        footerRender={() => (
          <DefaultFooter copyright="2024 Copyright, All Right Reserved, Made by RMS InfoSolution ❤️" />
        )}
      >
        <Outlet />
      </ProLayout>
    </div>
  );
};

export default memo(Layout);
