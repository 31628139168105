import { AxiosError } from 'axios';
// import { toast } from 'sonner';
import { message } from 'antd';
import GoogleLogo from '../assets/site-logo/google.png';
import FacebookLogo from '../assets/site-logo/facebook.png';
import BookingsLogo from '../assets/site-logo/booking.png';
import ExpediaLogo from '../assets/site-logo/expedia.png';
import HotellsLogo from '../assets/site-logo/hotels.png';
import TripAdvisorLogo from '../assets/site-logo/tripadvisor.png';

export const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;

export const NotificationType = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const setPageTitle = (title) => {
  window.document.title = title;
};

export const showNotification = (
  message_text = 'Something went wrong',
  type = NotificationType.ERROR,
  description
) => {
  if (type === NotificationType.SUCCESS) {
    message.success(message_text);
  } else {
    message.error(message_text);
  }
  // toast[type](message, {
  //   description: description,
  // });
};

export const handleErrorResponse = (
  error, // eslint-disable-line @typescript-eslint/no-explicit-any
  callback,
  errorMessage
) => {
  console.error(error);

  if (!errorMessage) {
    errorMessage = 'Something went wrong';

    if (typeof error === 'string') {
      try {
        error = JSON.parse(error);
      } catch (error) {
        // do nothing
      }
    }

    if (error instanceof AxiosError && error?.response?.data?.message) {
      errorMessage = error.response.data.message;
    } else if (error?.message) {
      errorMessage = error.message;
    }
  }

  showNotification(
    errorMessage &&
      errorMessage?.charAt(0).toUpperCase() + errorMessage.slice(1),
    NotificationType.ERROR
  );

  if (callback) {
    return callback();
  }
};

export const getSiteLogo = (id) => {
  switch (id) {
    case 1:
      return <img src={GoogleLogo} alt="Google" width={80} height={80} />;
    case 2:
      return (
        <img src={BookingsLogo} alt="Bookings.com" width={80} height={80} />
      );
    case 3:
      return <img src={FacebookLogo} alt="Facebook" width={80} height={80} />;
    case 4:
      return (
        <img src={TripAdvisorLogo} alt="Tripadvisor" width={80} height={80} />
      );
    case 5:
      return <img src={ExpediaLogo} alt="Expedia" width={80} height={80} />;
    case 6:
      return <img src={HotellsLogo} alt="Hotels" width={80} height={80} />;

    default:
      return '-';
  }
};
